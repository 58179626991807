import axios from "./axios.js"

export class MailSettings {

    static getMailSettings() {
        return axios.get("/mailsettings");

    }
    static getMailSetting(id) {
        let defaultURL = `/mailsettings/${id}`;
        return axios.get(defaultURL);
    }
    static createMailSetting(mailsetting) {
        let defaultURL = `/mailsettings`;
        return axios.post(defaultURL, mailsetting);
    }
    static updateMailSetting(mailsetting, id) {
        let defaultURL = `/mailsettings/${id}`;
        return axios.put(defaultURL, mailsetting);
    }
    static deleteMailSetting(id) {
        let defaultURL = `/mailsettings/${id}`;
        return axios.delete(defaultURL);
    }

}