<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Mail Settings</h3>
                <!-- <pre>{{ form }}</pre> -->
                <form @submit="onSubmit" class="state-from">
                    <div class="row">

                        <div class="col-md-4">
                            <label for="fromName" class="form-label">From Name </label>
                            <input type="text" v-model="form.fromName" class="form-control" id="fromName"
                                placeholder="From Name" required>
                        </div>

                        <div class="col-md-4">
                            <label for="emailId" class="form-label">emailId </label>
                            <input type="email" v-model="form.emailId" class="form-control" id="emailId"
                                placeholder="emailId" required>
                        </div>
                        <div class="col-md-4">
                            <label for="emaiPasswrod" class="form-label">Email Password </label>
                            <input type="password" v-model="form.emaiPasswrod" class="form-control" id="emaiPasswrod"
                                placeholder="State Email Password" required>
                        </div>
                        <div class="col-md-4">
                            <label for="smtpServerName" class="form-label">smtpServerName </label>
                            <input type="text" v-model="form.smtpServerName" class="form-control" id="smtpServerName"
                                placeholder="smtpServerName" required>
                        </div>
                        <div class="col-md-2">
                            <label for="smtpServerPort" class="form-label">smtpServerPort </label>
                            <input type="text" v-model="form.smtpServerPort" class="form-control" id="smtpServerPort"
                                placeholder="smtpServerPort" required>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="requireSSL" class="form-label"> SSL Status </label>
                            <select id="requireSSL" v-model="form.requireSSL" class="form-select"
                                aria-label="Default select example">
                                <option selected Disabled>Select SSL Status</option>
                                <option value="1">Yes </option>
                                <option value="0">No </option>
                            </select>
                        </div>
                    </div>


                    <input type="submit" :value="btnText" class="btn  btn-primary" />
                </form>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>smtpServerName</th>
                        <th>smtpServerPort</th>
                        <th>Email Id</th>
                        <th>SSL Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ mailsettings.id }}</td>
                        <td>{{ mailsettings.fromName }}</td>
                        <td>{{ mailsettings.smtpServerName }}</td>
                        <td>{{ mailsettings.smtpServerPort }}</td>
                        <td>{{ mailsettings.emailId }}</td>
                        <td>{{ mailsettings.requireSSL }}</td>
                        <td><button class="btn btn-success" @click="editdata(mailsettings.id)">Edit</button> <button
                                class="btn btn-danger" @click="deletedata(mailsettings.id)">Delete</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
</template>
<script>

import { MailSettings } from '../services/MailSettings'
import Spinner from '../components/Spinner.vue';
export default {
    name: 'MailSettings',
    components: {
        Spinner
    },
    data() {
        return {
            form: {
                id: '',
                fromName: '',
                smtpServerName: '',
                smtpServerPort: '',
                emailId: '',
                emaiPasswrod: '',
                requireSSL: '',

            },
            loading: false,
            mailsettings: [],


            btnText: 'Save',
            errorMasage: null
        }
    },
    async created() {

        try {
            this.loading = true
            let response = await MailSettings.getMailSettings();
            // console.log(response.data);
            this.mailsettings = response.data[0];
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
    }, methods: {
        async onSubmit(e) {
            e.preventDefault()
            if (!this.form.fromName) {
                alert('Please Enter Vendor name')
                return
            }



            // const response = await Country.createCountry(form);
            if (this.btnText === 'Save') {
                try {
                    this.loading = true;
                    const response = await MailSettings.createMailSetting(this.form);
                    console.log(response);
                    // if (response.data.status == 'success') {
                    //     alert('created');
                    // } else {
                    //     alert('error');
                    // }
                    this.form.fromName = "";
                    this.form.smtpServerName = "";
                    this.form.emailId = "";
                    this.form.smtpServerPort = "";
                    this.form.emaiPasswrod = "";
                    this.loading = false;
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.name);
                        this.errorMasage = error.response.data;
                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }
            } else {
                // alert('update');
                try {
                    this.loading = true;
                    const response = await MailSettings.updateMailSetting(this.form, this.form.id);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        // alert('Updated');
                    } else {
                        alert('error');
                    }
                    this.form.fromName = "";
                    this.form.smtpServerName = "";
                    this.form.emailId = "";
                    this.form.smtpServerPort = "";
                    this.form.emaiPasswrod = "";
                    this.loading = false;
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.name);

                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }
                this.btnText = "Save";
            }
            this.loading = true
            let response = await MailSettings.getMailSettings();
            // console.log(response.data);
            this.mailsettings = response.data[0];
            this.loading = false


        },
        async editdata(id) {
            try {


                // alert(id);
                this.loading = true;
                this.btnText = "Update";
                const response = await MailSettings.getMailSetting(id);
                this.form.id = id;
                this.form.fromName = response.data.fromName;
                this.form.smtpServerName = response.data.smtpServerName;
                this.form.smtpServerPort = response.data.smtpServerPort;
                this.form.emailId = response.data.emailId;
                this.form.emaiPasswrod = response.data.emaiPasswrod;
                this.form.requireSSL = response.data.requireSSL;
                console.log(response.data.name);
                // this.btnText = "Save";
                this.loading = false;
            }
            catch (error) {
                // console.log("this is error ", error)
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);

                }
                this.loading = false;
                // console.log(errors.value.name);
            }
        },
        async deletedata(id) {
            alert(id);
            this.loading = true;
            const resDel = await MailSettings.deleteMailSetting(id)
            console.log(resDel);
            // this.loading = true
            let response = await MailSettings.getMailSettings();
            // console.log(response.data);
            this.mailsettings = response.data[0];
            this.loading = false
        }
    },
}
</script>
<style>

</style>